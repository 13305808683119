<template>
  <div class="pol">

    <div class="sub-title f-b">{{app_name}}钻石充值协议</div>

    <div class="tips f-b">提示：本APP的钻石充值服务由海南悠蓝网络科技有限公司（以下简称“我方”）向您提供。</div>
    <div class="desc">
    为了向您提供优质的服务，您应仔细阅读并同意《钻石充值协议》（以下简称“本协议”）请您务必审慎阅读、充分了解各条款内容，特别是关于免除或者限制责任、管辖与法律适用的条款，以及开通或使用某项服务的单独协议。除非您已阅读并接受本协议所有条款，否则您无权使用我方提供的服务。您一旦开始使用我方提供的钻石服务，即视为您已阅读并同意上述协议。
    我方为旗下社交平台的有权开发商、内容提供方和运营商，提供视频内容服务的储存、介入、播放以及内容运营。
    使用钻石充值服务时，请务必保证您已满18岁，属于完全民事行为能力人，您未满18岁但依然继续充值或其他与您的民事行为能力相适应的行为，将视为已取得监护人或者法定代理人的同意。
    </div>
    <div class="lists">
      <p class="f-b">1、【协议的范围】</p>
      <p>1.1 本协议是您与我方之间关于用户使用钻石相关服务所订立的协议。“我方”是指海南悠蓝网络科技有限公司及其相关服务可能存在的运营关联单位。“用户”是指使用钻石服务的使用人，在本协议中更多地称为“您”。</p>
      <p>1.2 本协议项下的服务是我方向用户提供的平台钻石充值、消费余额查询等产品及服务（以下简称“本服务”）。</p>

      <p class="f-b">2、【“钻石”购买规则及说明】</p>
      <p>2.1 “钻石”是指海南悠蓝网络科技有限公司向您提供的用于在旗下平台上进行相关消费的虚拟货币，您可以用“钻石”在我方旗下社交平台上进行自由购买虚拟礼物，或者其他产品与服务的行为。您可将购买的虚拟礼物赠与其他注册用户。您应根据自己的实际需求购买并获得相应数量的“钻石”。
    钻石的使用范围为：我方旗下社交平台，即佳伴APP。</p>
      <p>2.2 人民币购买钻石的比例以充值界面显示为准。您购买钻石后的到账数量可能因为平台活动政策的变化而有所变化。具体到账数额以您购买钻石相关渠道服务页面显示为准。公司有权根据运营情况随时变更上述兑换比例，并将在您购买钻石相关渠道服务页面显示。</p>
      <p>2.3 您知晓并同意，钻石一经购买成功，除法律法规明确规定外，在任何情况下不得以任何形式兑换为法定货币。如因您违反国家法律法规、违反本协议或其他与软件及相关服务相关的协议和规则，平台有权暂时或永久封禁您的账号，账号封禁后，您账户上的剩余钻石或虚拟礼物/道具将被暂时冻结或全部扣除，同时不予返还您购买钻石时的现金价值。</p>
      <p>2.4 您暂时只能通过手机APP与移动设备开发者认可的方式购买“钻石”并支付相关服务费用。</p>
      <p>2.5 我方保留根据相关法律规定、主管部门要求、业务开展情况等因素对使用人民币购买“钻石”的规则进行单方面变更、调整、中止或终止的权利。您同意无条件接受对上述购买规则的变更、调整、中止或终止，我方在平台开展前述行动时，将在平台公布或者以其他方式通知公示，并自公布之日起自动生效，而无需另行单独通知您，也不就该行动给您造成的任何损失承担任何责任。</p>

      <p class="f-b">3、【钻石兑换操作的不可逆】</p>
      <p>用户同意并认可钻石兑换操作的不可逆性即当您使用钻石兑换虚拟礼物/道具时，您知晓上述钻石一经兑换即视为消费完成。兑换形成的虚拟道具后仅可用于自用穿戴或在平台上消费使用。</p>

      <p class="f-b">4、【使用本服务的方式】</p>
      <p> 您应当通过我方提供或认可的方式使用本服务。您依本协议条款所取得的权利不可转让，您不得使用未经我方授权的插件、外挂或第三方工具对本协议项下的服务进行干扰、破坏、修改或施加其他影响。</p>

      <p class="f-b">5、【按现状提供服务】</p>
   <p> 您理解并同意，钻石的服务是按照现有技术和条件所能达到的现状提供的。我方会尽最大努力向您提供服务，确保服务的连贯性和安全性；但我方不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险，因为上述原因给您带来的不便，请谅解。</p>

      <p class="f-b">6、【用户违法行为】</p>
      <p>6.1、您在使用本服务时须遵守法律法规，不得利用本服务从事违法违规行为，包括但不限于：</p>
      <p>（1）发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；</p>
      <p>（2）发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；</p>
      <p>（3）恶意虚构事实、隐瞒真相以误导、欺骗他人；</p>
      <p>（4）发布、传送、传播广告信息及垃圾信息；</p>
      <p>（5）其他法律法规禁止的行为。</p>
      <p>6.2、您仔细阅读并同意，若因您的使用行为给相关用户或平台造成损害或损失，由此引起相关国家机关、机构对您提起诉讼、罚款或采取其他制裁措施，涉事国家机关、机构要求我方提供您使用我方服务的相关记录的，我方将按照法律规定处理。因您的使用行为引起的相关法律责任，应当由您承担，我方不承担任何责任。</p>
      <p> 6.3、如果我方发现或收到他人举报您发布的信息违反本条约定，我方有权依据法律规定、app使用协议、本协议、移动设备相关开发计划等独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，我方有权视用户的行为性质，采取包括但不限于暂停或终止服务，封禁、限制、冻结或终止账号使用，扣除、冻结收益及追究法律责任等措施。</p>
      <p>6.4、您违反本条约定，导致任何第三方损害的，您应当独立承担责任；我方因此遭受损失的，您也应当一并赔偿。</p>

      <p class="f-b">7、【遵守中华人民共和国法律监管】</p>
      <p>7.1、您在使用本服务过程中应当遵守中华人民共和国相关的法律法规，并尊重社会道德和各地风俗习惯。如果您的行为违反了中华人民共和国法律法规或道德风俗，您应当为此独立承担责任。</p>
      <p>7.2、您应避免因使用本服务而使我方卷入政治和公共事件，否则我方有权暂停或终止对您的服务。</p>

      <p class="f-b"> 8、【用户发送、传播的内容与第三方投诉处理】</p>
      <p>8.1、您通过本服务发送或传播的内容（包括但不限于网页、文字、图片、音频、视频、图表等）均由您自行承担责任。</p>
      <p>8.2、您发送或传播的内容应有合法来源，相关内容为您所有或您已获得权利人的授权。</p>
      <p>8.3、您同意我方可为履行本协议或提供本服务的目的而使用您发送或传播的内容。</p>
      <p> 8.4、如果我方收到权利人通知，主张您发送或传播的内容侵犯其相关权利的，您同意我方有权进行独立判断并采取删除、屏蔽或断开链接等措施。</p>
      <p>8.5、您使用本服务时不得违反国家法律法规、侵害他人合法权益。您理解并同意，如您被他人投诉侵权或您投诉他人侵权，我方有权将争议中相关方的主体、联系方式、投诉相关内容等必要信息提供给其他争议方或相关部门，以便及时解决投诉纠纷，保护他人合法权益。</p>

      <p class="f-b">9、【不可抗力及其他免责事由】</p>
      <p>9.1、您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，我方将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失我方在法律允许的范围内免责。</p>
      <p>9.2、在法律允许的范围内，我方对以下情形导致的服务中断或受阻不承担责任：</p>
      <p>（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</p>
      <p>（2）用户或我方的电脑、手机软件、系统、硬件和通信线路出现故障；</p>
      <p>（3）用户因为自身原因操作不当、误看、误充值等等；</p>
      <p>（4）用户通过非我方授权的方式使用本服务；</p>
      <p>（5）其他我方无法控制或合理预见的情形；</p>
      <p>（6）用户充值钻石后，已在本平台作出例如社区互动、打赏、购买虚拟礼物等消费行为的，您理解并同意，本平台对于已消费的钻石不提供退款服务。</p>
      <p>9.3、关于用户因为自身原因、出现误看、误充值、理解错误等操作不当的行为的，我方不承担法律责任，且有权不予退款。</p>
      <p>9.4、您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，我方不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：</p>
      <p>（1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；</p>
      <p>（2）因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；</p>
      <p>（3）其他因网络信息或用户行为引起的风险。</p>
      <p>9.5、您理解并同意，本服务并非为某些特定目的而设计，包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，我方不承担法律责任。</p>
      <p> 9.6、我方依据本协议约定获得处理违法违规内容的权利，该权利不构成我方的义务或承诺，我方不能保证及时发现违法行为或进行相应处理。</p>
      <p> 9.7、在任何情况下，您不应轻信借款、索要密码、充值打赏或其他涉及财产的网络信息。为保证您的财产安全，请勿轻信他人及添加他人第三方平台联系方式。涉及财产操作的，请一定先核实对方身份，并请经常留意我方有关防范诈骗犯罪的提示。</p>
      <p> 9.8、您理解并同意：</p>
      <p>（1）如您发生退款行为，平台超出充值比例额外赠送的钻石部分不在为您退款的行列，平台有权仅退款您实际支付部分的金额，用户无权对超出实际支付金额部分的钻石数值提出退款需求，即若您充值了6元，假设根据充值活动实际到账金额为1200钻石，若您就该笔交易产生退款，平台仅退还您实际支付的6元；</p>
      <p>（2）如发生用户退款行为，平台有权追踪退款用户消费金额款项流动去向及消费对象，并有权向该用户消费对象追回其所有消费款项，包括平台额外赠送部分。</p>

      <p class="f-b">10、【协议的生效与变更】</p>
      <p>10.1、您使用我方的服务即视为您已阅读本协议并接受本协议的约束。</p>
      <p>10.2、我方有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。</p>
      <p>10.3、本协议条款变更后，如果您继续使用我方提供的软件或服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用我方提供的软件或服务。</p>

      <p class="f-b">11、【服务的变更、中断、终止】</p>
      <p>11.1、我方可能会对服务内容进行变更，也可能会中断、中止或终止服务。</p>
      <p>11.2、您理解并同意，我方有权自主决定经营策略。在我方发生合并、分立、收购、资产转让时，我方可向第三方转让本服务下相关资产；我方也可在单方通知您后，将本协议下部分或全部服务转交由第三方运营或履行。具体受让主体以我方通知的为准。</p>
      <p> 11.3、如发生下列任何一种情形，我方有权不经通知而中断或终止向您提供的服务：</p>
      <p>（1）根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；</p>
      <p>（2）您违反相关法律法规或本协议的约定；</p>
      <p>（3）按照法律规定或主管部门的要求；</p>
      <p>（4）出于安全的原因或其他必要的情形。</p>
      <p> 11.4、您有责任自行备份存储在本服务中的数据。如果您的服务被终止，我方可以从服务器上永久地删除您的数据,但法律法规另有规定的除外。服务终止后，我方没有义务向您返还数据。</p>

      <p class="f-b"> 12、【管辖与法律适用】</p>
      <p>12.1、本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</p>
      <p> 12.2、本协议签订地为中华人民共和国广东省深圳市南山区。</p>
      <p> 12.3、若您和我方之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地（即福建省厦门市集美区）有管辖权的人民法院管辖。</p>
      <p> 12.4、本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
      <p> 12.5、本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
    </div>
  </div>
</template>

<script>
import { getUrlQueryString } from '@/utils/util'
export default {
  name: 'RechargePolicy',
  data() {
    return {
      driver: null,
      app_name: '佳伴'
    }
  },
  created() {
    document.title = '钻石充值协议'
    this.app_name = getUrlQueryString('app_name')
  },
  methods: {
    guide() {

    }
  }
}
</script>

<style lang="less">
#app {
  text-align: left;
  .pol {
    line-height: 80px;
    padding: 20px;
    box-sizing: border-box;
    .title {
      font-weight: bold;
      text-align: center;
      padding: 20px 0px;
    }
  }
}

</style>